<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent>
      <b-row>
        <b-col md="9">
          <Fieldset id="information" :title="$t('Basic Information')">
            <b-form-group :label="`*` + $t('Title') + `:`" label-cols-md="12">
              <validation-provider
                #default="{ errors }"
                name="Title"
                rules="required"
              >
                <b-form-input
                  v-model="model.title"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Title')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </Fieldset>
          <Fieldset id="gallery" :title="$t('Gallery')">
            <b-row>
              <!-- <b-col md="3">
                <p><b>*Photos(0)</b></p>
                <p class="text-muted">
                  Tips:
                </p>
                <ul>
                  <li>- Use natural light and no flash.</li>
                  <li>- Include a common object for scale.</li>
                  <li>- Show the item being held, worn, or used.</li>
                  <li>- Shoot against a clean, simple background.</li>
                </ul>
              </b-col> -->
              <b-col md="12">
                <div class="d-flex align-items-center">
                  <p class="flex-grow-1">
                    {{ $t('We recommend adding 3 more photos') }}
                  </p>
                  <p @click="handlerDeleteAll">
                    {{ $t('Delete All') }}
                  </p>
                </div>
                <b-row class="no-gutters">
                  <b-col
                    md="3"
                    class="
                      fade-browse
                      d-flex
                      align-items-center
                      justify-content-center
                      review-img-avatar
                    "
                  >
                    <div>
                      <b-button
                        variant="danger"
                        class="review-img__del_avatar btn-icon rounded-circle"
                      >
                        <feather-icon icon="Edit3Icon" />
                      </b-button>
                      <b-form-file
                        accept=".jpg, .png, .gif, .jpeg"
                        class="
                          w-100
                          h-100
                          d-flex
                          align-items-center
                          justify-content-center
                          img-box
                        "
                        @change="handlerChangeAvatar"
                      />
                      <b-aspect style="width: 100%" aspect="1:1">
                        <b-img-lazy
                          v-if="avatar && avatar !== null"
                          :src="avatar"
                          fluid
                          thumbnail
                          rounded
                          class="w-100"
                          style="object-fit: contain"
                        />
                      </b-aspect>
                    </div>
                  </b-col>
                  <b-col md="9">
                    <draggable
                      v-model="list_thumbnail_edit"
                      tag="ul"
                      class="cursor-move d-flex flex-wrap"
                    >
                      <b-col
                        v-for="(item, index) in list_thumbnail_edit"
                        :key="index"
                        class="review-img mb-1"
                        md="3"
                      >
                        <b-button
                          variant="danger"
                          class="review-img__del btn-icon rounded-circle"
                          @click.stop="delImg(index)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                        <b-aspect aspect="1:1">
                          <b-img-lazy
                            :src="item.full_link"
                            fluid
                            thumbnail
                            rounded
                            class="w-100"
                            style="object-fit: contain"
                          />
                        </b-aspect>
                      </b-col>
                    </draggable>
                  </b-col>
                  <b-col md="12" class="pt-1">
                    <div
                      class="
                        fade-browse
                        text-center
                        align-items-center
                        justify-content-center
                        w-100
                        p-2
                        rounded
                      "
                    >
                      <p class="pt-1">
                        {{ $t('Add upto 12 photos so buyers can see every detail') }}
                      </p>
                      <div class="d-flex flex-wrap">
                        <b-col md="6">
                          <b-form-file
                            id="input-product"
                            multiple
                            accept=".jpg, .png, .gif, .jpeg"
                            class="img-box"
                            @change="previewFiles"
                          />
                          <label for="input-product" class="btn-add">
                            <feather-icon icon="UploadCloudIcon" />
                            {{ $t('Upload from your PC') }}
                          </label>
                        </b-col>
                        <b-col md="6">
                          <label class="btn-add">
                            <feather-icon icon="PlusCircleIcon" />
                            {{ $t('Add from Media center') }}
                          </label>
                        </b-col>
                      </div>
                    </div>
                    <!-- <p
                      class="text-muted mt-1 text-center mx-auto"
                      style="font-size: 12px"
                    >
                      {{ $t('Add up to 12 photos. We do not allow photos with extra borders, text or artwork') }}
                    </p> -->
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </Fieldset>
        </b-col>
        <b-col md="3">
          <div class="position-sticky top">
            <Fieldset id="category" :title="$t('Category')">
              <div class="d-flex align-items-center">
                <validation-provider
                  #default="{ errors }"
                  name="Gallery category"
                  rules="required"
                  class="w-100 mr-1"
                >
                  <v-select
                    v-if="categoryOptions"
                    v-model="gallery_category_id"
                    label="title"
                    :reduce="(x) => x.id"
                    :options="categoryOptions"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <b-link>
                  <feather-icon
                    v-b-modal.modal-gallery-cate
                    icon="PlusCircleIcon"
                    size="28"
                  />
                </b-link>
              </div>
            </Fieldset>
            <Fieldset id="status" :title="$t('Status')">
              <b-form-group :label="$t('Display on/off')+`:`" label-cols-md="12">
                <b-form-checkbox
                  v-model="model.is_active"
                  checked="true"
                  class="custom-control-success"
                  name="check-button"
                  switch
                />
              </b-form-group>
              <b-form-group :label="$t('Position')+`:`" label-cols-md="12">
                <b-form-input
                  v-model="model.position"
                  type="number"
                  :placeholder="$t('Position')"
                />
              </b-form-group>
              <hr>
              <!-- Submit -->
              <div class="text-right">
                <b-button
                  variant="primary"
                  type="submit"
                  class="text-right"
                  @click.prevent="validationForm"
                >
                  {{ $t('Update') }}
                </b-button>
              </div>
            </Fieldset>
          </div>
        </b-col>
      </b-row>
      <b-modal
        id="modal-gallery-cate"
        centered
        size="xl"
        title="Add new gallery category"
      >
        <GalleryCateAdd />
      </b-modal>
    </b-form>
  </validation-observer>
</template>

<script>
/* eslint-disable operator-linebreak */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormFile,
  BImgLazy,
  BAspect,
  BFormCheckbox,
  BLink,
} from 'bootstrap-vue'

import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import draggable from 'vuedraggable'
import vSelect from 'vue-select'
import GalleryCateAdd from './GalleryCategoryAdd.vue'

const isBase64 = require('is-base64')

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BForm,
    BFormFile,
    BImgLazy,
    BAspect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    draggable,
    BFormCheckbox,
    BLink,
    GalleryCateAdd,
  },
  directives: {
    Ripple,
  },

  mixins: [general],
  data() {
    return {
      required,
      avatar: null,
      list_thumbnail: [],
      list_thumbnail_edit: [],
      gallery_category_id: null,
      model: {},
      categoryOptions: null,
    }
  },
  created() {
    this.loadDetail()
  },
  methods: {
    handlerDeleteAll() {
      this.avatar = null
      this.list_thumbnail_edit = []
    },
    delImg(index) {
      this.list_thumbnail_edit.splice(index, 1)
    },
    handlerChangeAvatar(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
    async loadDetail() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/gallery/${this.$route.params.id}?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.detailDefault = { ...res.data.data }
            this.categoryOptions = await this.loadCategoryList()
            const model = await {
              ...res.data.data,
              list_thumbnail: res.data.data.list_thumbnail,
              list_thumbnail_id:
                res.data.data.list_thumbnail_id !== null
                  ? res.data.data.list_thumbnail_id.split(',')
                  : null,
              list_thumbnail_path: JSON.parse(
                res.data.data.list_thumbnail_path,
              ),
            }
            if (
              res.data.data.list_thumbnail &&
              res.data.data.list_thumbnail.length > 0
            ) {
              res.data.data.list_thumbnail.map(async (x, idx) => {
                const i = {
                  id: model.list_thumbnail_id[idx],
                  full_link: x,
                  path: model.list_thumbnail_path[idx],
                }
                this.list_thumbnail_edit.push(i)
              })
            }
            this.model = await this.getDataTranslation(model, false)
            this.gallery_category_id = this.categoryOptions.filter(
              value => value.id_root === res.data.data.gallery_category_id_root,
            )[0]
            this.avatar = model.avatar
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadGalleryDetail(id) {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/gallery_category/${id}?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.gallery_category_id = res.data.data
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const translation = {
              ...this.detailDefault.translation,
            }

            translation[await this.getEditLanguage()] = {
              title: this.model.title,
            }
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              position: Number(this.model.position),
              is_active: Number(this.model.is_active === true ? 1 : 0),
              gallery_category_id:
                this.gallery_category_id && this.gallery_category_id.id
                  ? this.gallery_category_id.id
                  : this.gallery_category_id,
              translation: JSON.stringify(translation),
            }
            if (isBase64(this.avatar, { allowMime: true })) {
              params.avatar = this.avatar
            }
            if (
              (await this.getEditLanguage()) ===
              JSON.parse(localStorage.getItem('siteID')).language
            ) {
              // Default language = current language -> get current info
              params.title = this.model.title
            } else {
              //  Default language != current langue -> get default info
              params.title = this.detailDefault.title
            }
            if (
              this.list_thumbnail_edit &&
              this.list_thumbnail_edit.length > 0
            ) {
              let listThumbnailId = null
              const listThumbnail = []
              let index = 0
              this.list_thumbnail_edit.map(async x => {
                if (isBase64(x.base64, { allowMime: true })) {
                  const thumbnail = {
                    site_id: JSON.parse(localStorage.getItem('siteID')).id,
                    type_table: 'g_m',
                    type: 'desktop',
                    avatar: x.base64,
                    table_field: 'list_thumbnail',
                  }
                  const resIMG = await Request.post(
                    this.$http,
                    `${process.env.VUE_APP_API_IMG_URL}/images_only`,
                    thumbnail,
                  )
                  if (resIMG.status === 200) {
                    if (resIMG.data.status) {
                      const item = {
                        id: resIMG.data.data.id,
                        full_link: resIMG.data.data.image,
                        path: resIMG.data.data.path,
                      }
                      if (listThumbnailId) {
                        listThumbnailId += `,${item.id}`
                      } else {
                        listThumbnailId = item.id
                      }
                      listThumbnail.push(item.path)
                    } else {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Opps! Something wrong',
                          icon: 'AlertOctagonIcon',
                          variant: 'danger',
                          text: String(this.showError(resIMG.data.error, ',')),
                        },
                      })
                    }
                  }
                } else {
                  if (listThumbnailId) {
                    listThumbnailId += `,${x.id}`
                  } else {
                    listThumbnailId = x.id
                  }
                  listThumbnail.push(x.path)
                }
                index += 1
                if (index === this.list_thumbnail_edit.length) {
                  params.list_thumbnail_id = listThumbnailId
                  params.list_thumbnail = JSON.stringify(listThumbnail)
                  this.handlerUpdate(params)
                }
              })
            } else {
              this.handlerUpdate(params)
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    previewFiles(input) {
      let i = 0
      if (
        // eslint-disable-next-line operator-linebreak
        input.target.files.length + this.list_thumbnail_edit.length <=
        12
      ) {
        // eslint-disable-next-line no-plusplus
        for (i; i < input.target.files.length; i++) {
          const file = input.target.files[i]
          const reader = new FileReader()
          // eslint-disable-next-line no-loop-func
          if (i > 0) {
            reader.onload = e => {
              const item = {
                id: null,
                full_link: e.target.result,
                path: null,
                base64: e.target.result,
              }
              this.list_thumbnail_edit.push(item)
            }
          } else {
            reader.onload = e => {
              if (this.avatar !== null) {
                const item = {
                  id: null,
                  full_link: e.target.result,
                  path: null,
                  base64: e.target.result,
                }
                this.list_thumbnail_edit.push(item)
              } else {
                this.avatar = e.target.result
              }
            }
          }
          reader.onerror = error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
          reader.readAsDataURL(file)
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String('Maximum is 12 picture'),
          },
        })
      }
    },
    // previewFiles(input) {
    //   let i = 0
    //   if (input.target.files.length + this.list_thumbnail.length <= 12) {
    //     for (i; i < input.target.files.length; i++) {
    //       const file = input.target.files[i]
    //       const reader = new FileReader()
    //       if (i > 0) {
    //         reader.onload = e => {
    //           const item = {
    //             id: null,
    //             full_link: e.target.result,
    //             path: null,
    //             base64: e.target.result,
    //           }
    //           this.list_thumbnail_edit.push(item)
    //         }
    //       } else {
    //         reader.onload = e => {
    //           if (this.avatar !== null) {
    //             const item = {
    //               id: null,
    //               full_link: e.target.result,
    //               path: null,
    //               base64: e.target.result,
    //             }
    //             this.list_thumbnail.push(item)
    //           } else {
    //             this.avatar = e.target.result
    //           }
    //         }
    //       }
    //       reader.onerror = error => {
    //         this.$toast({
    //           component: ToastificationContent,
    //           position: 'top-right',
    //           props: {
    //             title: 'Opps! Something wrong',
    //             icon: 'AlertOctagonIcon',
    //             variant: 'danger',
    //             text: String(error),
    //           },
    //         })
    //       }
    //       reader.readAsDataURL(file)
    //     }
    //   } else {
    //     this.$toast({
    //       component: ToastificationContent,
    //       position: 'top-right',
    //       props: {
    //         title: 'Opps! Something wrong',
    //         icon: 'AlertOctagonIcon',
    //         variant: 'danger',
    //         text: String('Maximum is 12 picture'),
    //       },
    //     })
    //   }
    // },
    async loadCategoryList() {
      try {
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/gallery_categories?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            return await this.getDataTranslation(res.data.data.items, true)
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return false
    },
    async handlerUpdate(params) {
      const res = await Request.put(
        this.$http,
        `${process.env.VUE_APP_API_URL}/gallery/${this.$route.params.id}`,
        params,
      )
      if (res.status === 200) {
        if (res.data.status) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Congratulation !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Update Gallery success',
            },
          })
          this.$router.push('/gallery/list')
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(this.showError(res.data.error, ',')),
            },
          })
        }
      }
    },
  },
}
</script>

<style lang='scss'>
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang='scss' scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.review-img,
.review-img-avatar {
  position: relative;

  &::before {
    position: absolute;
    content: 'Drag to Sort';
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: rgba(100, 100, 100, 0.75);
    left: 1rem;
    right: 1rem;
    bottom: 0;
    top: 0;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    pointer-events: none;
  }
  &__del {
    position: absolute;
    z-index: 2;
    opacity: 0;
    top: 0;
    right: 0;
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    &::before,
    .review-img__del,
    .review-img__del_avatar {
      opacity: 1;
    }
  }
}
.review-img-avatar {
  &::before {
    content: '';
    width: 100%;
    left: 0;
  }
  .review-img__del_avatar {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 2;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    z-index: 1;
    width: 100%;
    min-height: 3rem;
    border: 1px solid var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .img-box {
    position: absolute;
    z-index: 2;
    opacity: 0;
    top: 0;
    left: 1rem;
    right: 1rem;
    width: 0;
  }
}
</style>
